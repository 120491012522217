.empty {
  text-align: center;
  padding: 183px 0;
  width: 100%;
}
.empty-text {
  color: #999;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: -0.24px;
}
